import React, { useState, useEffect, useRef } from "react";
import ResetButton from "assets/resetbutton.jpg";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBanner from "components/commontools/toolsBanner";
import ToolInput from "components/commontools/InsertTool";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import GuidelineOutput from "components/commontools/GuidelineOutput";
import { navigate } from "gatsby";
import CleanButton from "assets/cleanBtn.svg";
import ToolFaq from "components/QualityTools/ToolFaq";
import { GuidanceToolFaqData } from "components/FAQ/config";
import detectLanguage from "utils/detectLanguage";
import useDebounce from "components/QualityTools/useDebounce";
const Guidelinegenerator = () => {
  const [divHeight, setDivHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [guidelineText, setGuidelineText] = useState("");
  const [inputLang, setInputLang] = useState("");
  const [outputLang, setOutputLang] = useState("");
  const [guidelineLang, setGuidelineLang] = useState("");
  const refDiv = useRef(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [wordCountInput, setWordCountInput] = useState(0);
  const [wordCountOutput, setWordCountOutput] = useState(0);
  const [guidelineCount, setGuidelineCount] = useState(0);
  const [isLgScreen, setIsLgScreen] = useState(false);
  const [shareId, setShareId] = useState("");
  const outputRef = useRef(null);

  const debouncedInputText = useDebounce(inputText, 3000);
  const debouncedOutputText = useDebounce(outputText, 3000);
  const debouncedGuidelineText = useDebounce(guidelineText, 3000);
   const [isEnterText, setIsEnterText] = useState(true);

  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);

  useEffect(() => {
    if (debouncedOutputText?.trim()?.length >= 3) {
      detectLanguageForTarget(debouncedOutputText);
    }
  }, [debouncedOutputText]);

  useEffect(() => {
    if (debouncedGuidelineText?.trim()?.length >= 3) {
      detectLanguageForGuideline(debouncedGuidelineText);
    }
  }, [debouncedGuidelineText]);

  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
      // setTranslateFromInput(languageName);
    }
  };

  const detectLanguageForTarget = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setOutputLang(languageCode);
      // setTranslateToInput(languageName);
    }
  };
  const detectLanguageForGuideline = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setGuidelineLang(languageCode);
    }
  };
  useEffect(() => {
    if (refDiv.current) {
      const height = refDiv.current.offsetHeight;
      setDivHeight(height);
    }
  }, [inputText, outputText, guidelineText, analysisResult]);

  const handleClick = async () => {
    if (isButtonDisabled) return;

    setLoading(true);
    setError("");
    setShowOutput(false);
    try {
      const response = await http().post(endpoints.forms.aiTools, {
        tool_name: "guideline_adherence_checker",
        source_text: inputText,
        translation: outputText,
        user_text: guidelineText,
        source_language_code: inputLang,
        target_language_code: outputLang,
        guideline_language_code: guidelineLang,
      });

      const newShareId = response?.meta?.share_id;
      setShareId(newShareId);
      setShowOutput(true);
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(
        `/tools/guideline-adherence-checker/result?share-id=${newShareId}`
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const isButtonDisabled = !guidelineText || !inputText || !outputText || !inputLang || !outputLang || !guidelineLang;

  const handleSourceClearText = () => {
    setInputText("");
    setInputLang("");
    setWordCountInput(0);
  };
  const handleTargetClearText = () => {
    setOutputLang("");
    setOutputText("");
    setGuidelineCount(0);
  };
  const handleGuidelineClearText = () => {
    setGuidelineText("");
    setWordCountOutput("");
  };
  useEffect(() => {
    if (showOutput && outputRef.current) {
      const outputPosition =
        outputRef.current.getBoundingClientRect().top + window.scrollY;

      window.scrollTo({
        top: outputPosition - 230,
        behavior: "smooth",
      });
    }
  }, [showOutput, analysisResult]);

  return (
    <Layout>
      <SEO
        title="Translation Guideline Adherence Checker"
        description="Check if your translation guidelines were followed by your translator with our Translation Guideline Adherence Checker."
        slug="/tools/guideline-adherence-checker"
      />
      <div className="bg-[#F5F5F5] ">
        <div className=" w-full  py-7 ">
          <ToolsBreadcrumb />
        </div>
        <div className="max-w-7xl mx-auto px-4 py-[60px]">
          <ToolsBanner
            text="Translation Guidelines Adherence Checker"
            title="Automated Guideline Checks for Efficient Quality Control"
            description="Enter your source text, translation, and guidelines. The tool checks for any deviations and provides a report with a compliance score and improvement suggestions. Meet your client's expectations and make revisions easier."
          />
          <div className="lg:flex gap-3 mt-12">
            <div className="flex flex-col gap-3 w-full lg:w-[70%]">
              <div className="lg:flex items-center  rounded-lg shadow-lg ">
                <ToolInput
                  text={inputText}
                  wordCount={wordCountInput}
                  setText={setInputText}
                  setInputLang={setInputLang}
                  setWordCount={setWordCountInput}
                  iscleanBtn={true}
                  refDiv={refDiv}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  lang={inputLang}
                  isEnterText={isEnterText}
                >
                  {" "}
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                    <p className=" text-[#6E6E6E] font-opensans font-bold">
                      Source text
                    </p>
                    {inputText && (
                      <img
                        src={CleanButton}
                        alt="clean-button"
                        onClick={handleSourceClearText}
                        className="cursor-pointer  absolute right-[14px]"
                      />
                    )}
                  </div>
                </ToolInput>

                <div className="hidden lg:block h-full border border-[#DEDEDE] "></div>

                <ToolInput
                  lang={outputLang}
                  text={outputText}
                  wordCount={guidelineCount}
                  setText={setOutputText}
                  setWordCount={setGuidelineCount}
                  refDiv={refDiv}
                  placeholder="Enter your text here"
                  isRefreshBtn={true}
                  isTranslation={true}
                  isEnterText={isEnterText}
                >
                  <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                    <p className=" text-[#6E6E6E] font-opensans font-bold">
                      Translated text
                    </p>
                    {outputText && (
                      <img
                        src={CleanButton}
                        alt="clean-button"
                        onClick={handleTargetClearText}
                        className="cursor-pointer  absolute right-[14px]"
                      />
                    )}
                  </div>
                </ToolInput>
              </div>
            </div>
            <ToolInput
              text={guidelineText}
              wordCount={wordCountOutput}
              setText={setGuidelineText}
              setWordCount={setWordCountOutput}
              refDiv={refDiv}
              divHeight={divHeight}
              placeholder="Enter your text here"
              isGuidance={true}
              buttontext="Evaluate Text"
              handleClick={handleClick}
              isButtonDisabled={isButtonDisabled}
              ResetButton={ResetButton}
              loading={loading}
              setDivHeight={setDivHeight}
              isTranslation={false}
              lang={guidelineLang}
              isEnterText={isEnterText}
            >
              <div className="pb-3 border-b border-[#B0B0B0] flex items-center justify-between ">
                <p className=" text-[#6E6E6E] font-opensans font-bold">
                  Guidelines
                </p>
                {guidelineText && (
                  <img
                    src={CleanButton}
                    alt="clean-button"
                    onClick={handleGuidelineClearText}
                    className="cursor-pointer  absolute right-[14px]"
                  />
                )}
              </div>
            </ToolInput>
          </div>
          <p className="w-full text-center text-base mt-12 mb-4 text-[#9C9C9C]">
            Assisted by AI tools. Inaccuracies may occur.
          </p>
          <div ref={outputRef}>
            <GuidelineOutput
              // divHeight={divHeight}
              isLgScreen={isLgScreen}
              loading={loading}
              error={error}
              refDiv={refDiv}
            />
          </div>
          <p className=" mt-12 text-lg text-center font-opensans leading-[26px]">
            Enter your source text, translation, and guidelines. The tool checks
            for any deviations and provides a report with a compliance score and
            improvement suggestions. Meet your client's expectations and make
            revisions easier.
          </p>
        </div>
      </div>
      <ToolFaq QaToolFaqData={GuidanceToolFaqData} />
    </Layout>
  );
};

export default Guidelinegenerator;
